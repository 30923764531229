import * as React from "react";

import styled from "styled-components";

import { FooterComponent } from "./FooterComponent";
import { HeaderLogoComponent } from "./HeaderLogoComponent";
import { HeaderMenuComponent } from "./HeaderMenuComponent";

const HeaderOuterContainer = styled.div`
  position: fixed;
  z-index: 9999999999;
  top: 0;

  padding: 0 20px;

  width: 100%;
  border-bottom: 3px solid var(--primaryColor);
  background: white;
  color: rgba(0, 0, 0, 0.85);

  a {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected:after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover:after {
    border-bottom: 5px solid var(--primaryColor);
  }

  .ant-menu-horizontal {
    border-bottom: 0px;
  }

  .customMenu h4 {
    font-size: 16px;
  }
`;

const HeaderInnerContainer = styled.div`
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  height: 85px;

  display: flex;
  //direction: ltr;
  justify-content: space-between;
`;

const HeaderMenuContainer = styled.div`
  width: 70%;
  @media only screen and (max-width: 600px) {
    width: 50px;
  }

  .ant-menu {
    line-height: 77px;
  }
`;

const HeaderLogoContainer = styled.div``;

const FooterOuterContainer = styled.div`
  width: 100%;
  border-top: 5px solid var(--primaryColor);
  background: #424242;
  background: var(--secondaryColor);
  color: white;
  color: var(--secondaryTextColor);

  a {
    color: white;
    color: var(--secondaryTextColor);

    &:hover {
      text-decoration: underline;
    }
  }
  padding: 20px 5px;

  .contactaffix {
    color: var(--primaryTextColor);
    background-color: var(--primaryColor);
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
      0 9px 28px 8px #0000000d;
    transition: color 0.3s;

    :hover {
      color: black;
    }
  }
`;

const FooterInnerContainer = styled.div`
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-direction: column;

  width: 100%;
  padding-top: 85px;
  background-image: linear-gradient(#f5f5f5, #d3d3d3);

  .ant-collapse-header  {
    background-color: #fcfcfc;
  }

  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border: 0px;
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row:first-child {
    border-bottom: 0px;
    background-color: #fcfcfc;
  }
  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row:last-child {
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    .ant-tabs-nav-wrap {
      justify-content: center;
    }

    .ant-typography h1,
    h1.ant-typography  {
      font-size: 26px;
    }

    .ant-typography h3,
    h3.ant-typography {
      font-size: 14px;
      margin: 0 !important;
    }
  }

  .ant-anchor-link {
    width: auto;
    float: left;
    font-size: 20px;
    display: flex;
    justify-content: center;
  }

  .ant-anchor-wrapper {
    overflow: initial;
  }

  .ant-anchor-link {
    width: 40px !important;
  }

  .ant-tabs-tab:hover {
    color: #000 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #444 !important;
  }
  .ant-tabs-ink-bar {
    background: var(--primaryColor) !important;
  }

  .frontpageboxbig {
    height: 300px;
    padding-top: 12px;
    width: 280px;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .frontpageboxbig {
    :hover {
      background-color: var(--primaryColor);
      color: var(--primaryTextColor);
      border: 8px solid var(--primaryTextColor);
    }
  }

  @media only screen and (max-width: 800px) {
    .frontboxouter {
      width: 100%;
    }
    .frontpageboxbig {
      height: 150px;
      width: 100%;

      padding-top: 15px;
    }
  }

  .frontpagebox {
    height: 210px;
    padding-top: 12px;
    width: 210px;
    background-color: var(--secondaryColor);
    color: var(--secondaryTextColor);
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .frontpagebox {
    :hover {
      background-color: var(--secondaryColor);
      color: var(--secondaryTextColor);
      border: 6px solid var(--secondaryTextColor);
    }
  }

  @media only screen and (max-width: 800px) {
    .frontpagebox {
      height: 100px;
      width: 100%;

      padding-top: 30px;
    }
  }

  .ant-tabs-nav-list {
    width: 100%;
    overflow-y: scroll;
  }
`;

type Props = React.PropsWithChildren<{
  fullWidth?: boolean;
}>;

export const AppLayout = React.memo<Props>(function AppLayout({
  children,
  fullWidth = false,
}) {
  return (
    <Container>
      <HeaderOuterContainer>
        <HeaderInnerContainer>
          <HeaderMenuContainer>
            <HeaderMenuComponent />
          </HeaderMenuContainer>
          <HeaderLogoContainer>
            <HeaderLogoComponent />
          </HeaderLogoContainer>
        </HeaderInnerContainer>
      </HeaderOuterContainer>
      <ContentContainer style={{ maxWidth: fullWidth ? "100%" : 1420 }}>
        {children}
      </ContentContainer>
      <FooterOuterContainer>
        <FooterInnerContainer>
          <FooterComponent />
        </FooterInnerContainer>
      </FooterOuterContainer>
    </Container>
  );
});
