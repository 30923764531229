import Parse from "parse";

import type { Language } from "./Language";

type OD3_Tenant = Parse.Object;

export interface VideoAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description?: string;
  image?: Parse.File;
  language: Language;
  order: number;
  tenant: OD3_Tenant;
  text?: string;
  title: string;
  video: Parse.File;
}

export class Video extends Parse.Object<VideoAttributes> {
  static className: string = "APP_Video";

  constructor(data?: Partial<VideoAttributes>) {
    super("APP_Video", data as VideoAttributes);
  }

  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get image(): Parse.File | undefined {
    return super.get("image");
  }
  set image(value: Parse.File | undefined) {
    super.set("image", value);
  }
  get language(): Language {
    return super.get("language");
  }
  set language(value: Language) {
    super.set("language", value);
  }
  get order(): number {
    return super.get("order");
  }
  set order(value: number) {
    super.set("order", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get text(): string | undefined {
    return super.get("text");
  }
  set text(value: string | undefined) {
    super.set("text", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
  get video(): Parse.File {
    return super.get("video");
  }
  set video(value: Parse.File) {
    super.set("video", value);
  }
}

Parse.Object.registerSubclass("APP_Video", Video);
