import * as React from "react";

import { Button, Space, Tooltip } from "antd";

import { useCurrentLanguage, changeLanguage } from "@opendash/i18n";
import { app, useAppState } from "../state";
import styled from "styled-components";
const flag = require("../../assets/ar.png");

const FlagButton = styled.button`
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  display: block;
  height: 18px;
  width: 30px;
  cursor: pointer;
  background: transparent;

  &:disabled {
    cursor: inherit;
  }
`;

export const LanguageControls = React.memo(function LanguageControls() {
  const currentLanguage = useCurrentLanguage();

  const state = useAppState();

  if (state.forceLanguage) {
    return null;
  }

  return (
    <Space direction="horizontal">
      <Tooltip overlay="Wähle Deutsch als deine bevorzugte Sprache">
        <>
          <FlagButton
            disabled={currentLanguage === "de"}
            onClick={() => {
              changeLanguage("de").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 5 3"
            >
              <path d="M0 0h5v3H0z" />
              <path fill="#D00" d="M0 1h5v2H0z" />
              <path fill="#FFCE00" d="M0 2h5v1H0z" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>

      <Tooltip overlay="Select English as your preferred language">
        <>
          <FlagButton
            disabled={currentLanguage === "en"}
            onClick={() => {
              changeLanguage("en").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 30"
              width="30"
              height="18"
            >
              <clipPath id="a">
                <path d="M0 0v30h60V0z" />
              </clipPath>
              <clipPath id="b">
                <path d="M30 15h30v15zv15H0zH0V0zV0h30z" />
              </clipPath>
              <g clipPath="url(#a)">
                <path d="M0 0v30h60V0z" fill="#012169" />
                <path d="M0 0l60 30m0-30L0 30" stroke="#fff" strokeWidth="6" />
                <path
                  d="M0 0l60 30m0-30L0 30"
                  clipPath="url(#b)"
                  stroke="#C8102E"
                  strokeWidth="4"
                />
                <path d="M30 0v30M0 15h60" stroke="#fff" strokeWidth="10" />
                <path d="M30 0v30M0 15h60" stroke="#C8102E" strokeWidth="6" />
              </g>
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="Tercih ettiğiniz dil olarak Türkçe'yi seçin">
        <>
          <FlagButton
            disabled={currentLanguage === "tr"}
            onClick={() => {
              changeLanguage("tr").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 1200 800"
            >
              <path fill="#E30A17" d="M0 0h1200v800H0z" />
              <circle cx="425" cy="400" r="200" fill="#fff" />
              <circle cx="475" cy="400" r="160" fill="#e30a17" />
              <path
                fill="#fff"
                d="M583.334 400l180.901 58.779-111.804-153.885v190.212l111.804-153.885z"
              />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="Выберите русский в качестве предпочитаемого языка">
        <>
          <FlagButton
            disabled={currentLanguage === "ru"}
            onClick={() => {
              changeLanguage("ru").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9 6"
              width="30"
              height="18"
            >
              <rect fill="#fff" width="9" height="3" />
              <rect fill="#d52b1e" y="3" width="9" height="3" />
              <rect fill="#0039a6" y="2" width="9" height="2" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="Selectați limba română ca limbă preferată">
        <>
          <FlagButton
            disabled={currentLanguage === "ro"}
            onClick={() => {
              changeLanguage("ro").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 3 2"
            >
              <rect width="3" height="2" x="0" y="0" fill="#002B7F" />
              <rect width="2" height="2" x="1" y="0" fill="#FCD116" />
              <rect width="1" height="2" x="2" y="0" fill="#CE1126" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="Изберете български като предпочитан език">
        <>
          <FlagButton
            disabled={currentLanguage === "bg"}
            onClick={() => {
              changeLanguage("bg").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 5 3"
            >
              <rect width="5" height="3" fill="#fff" />
              <rect width="5" height="2" y="1" fill="#00966E" />
              <rect width="5" height="1" y="2" fill="#D62612" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="Виберіть українську як бажану мову">
        <>
          <FlagButton
            disabled={currentLanguage === "uk"}
            onClick={() => {
              changeLanguage("uk").then(() => {
                document.documentElement.lang = "en";
                document.documentElement.dir = "ltr";
                app.init();
              });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="18"
              viewBox="0 0 3 2"
            >
              <rect width="3" height="2" fill="#005BBB" />
              <rect width="3" height="1" y="1" fill="#FFD500" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
      <Tooltip overlay="اختر اللغة العربية كلغتك المفضلة">
        <>
          <FlagButton
            style={{ marginTop: "-4px" }}
            disabled={currentLanguage === "ar"}
            onClick={() => {
              changeLanguage("ar").then(() => {
                document.documentElement.lang = "ar";
                document.documentElement.dir = "rtl";
                app.init();
              });
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 400">
              <image href={flag} x="0" y="0" width="640" height="480" />
            </svg>
          </FlagButton>
        </>
      </Tooltip>
    </Space>
  );
});
