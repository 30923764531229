import {
  AuthError,
  AuthPayloadInterface,
  SourceInterface,
  UserAdapterContext,
  UserAdapterInterface,
  UserInterface,
} from "@opendash/core";

export class LocalUserAdapter implements UserAdapterInterface {
  private context: UserAdapterContext = {} as UserAdapterContext;
  private user: UserInterface;

  constructor(config:any) {
    this.user = {
      id: "anonymous",
      username: "anonymous",
      name: "Anonymous",
      email: "anonymous@openinc.dev",
      session: "xxx",
    };
  }

  public async handlePushSubscription(
    id: string | undefined,
    type: string,
    data: any
  ): Promise<string> {
    throw new Error("Method not implemented.");
    return "";
  }


  async changePassword(data:any) {
    throw new Error("Method not implemented.");
  }

  onContext(context: UserAdapterContext) {
    this.context = context;

    this.init();
  }
  private handleError(
    error: unknown,
    defaultErrorCode: typeof AuthError.prototype.type
  ) {
    throw new Error("Method not implemented.");

  }

  public isLoggedIn() {
    return true;
  }
  

  private async init() {
    try {

      this.context.setCurrentUser(this.user);

      this.context.setLoading(false);
      this.context.setValidated(true);
      this.context.setOffline(false);
    } catch (error) {
      console.error(error);
      this.context.setCurrentUser(this.user);

      this.context.setLoading(false);
      this.context.setValidated(false);
      this.context.setOffline(false);
    }
  }

  async login(payload: AuthPayloadInterface) {
    await this.init();
  }

  async register(payload: AuthPayloadInterface) {
    await this.init();
  }

  async logout() {
    window.localStorage.clear();
    window.location.reload();
  }
}
