import * as React from "react";
import { Typography, Descriptions } from "antd";
import { AppLayout } from "./AppLayout";
import { ContactContent } from "./ContactContent";
import { useTranslation } from "@opendash/i18n";

const { Title } = Typography;

export const ContactRoute = React.memo(function ContactRoute() {
  const t = useTranslation();

  return (
    <AppLayout>
      <div style={{ padding: "10px" }}>
        <Title level={3}>{t("app:contact.title")}</Title>
      </div>
      <ContactContent />
    </AppLayout>
  );
});
