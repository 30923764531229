import * as React from "react";

import { useNavigate, useParams } from "@opendash/router";
import ReactMarkdown from "react-markdown";

import {
  Collapse,
  Space,
  Anchor,
  Input,
  Row,
  Col,
  Empty,
  Button,
  Typography,
  Affix,
} from "antd";

import { useAppState } from "../state";

import { AppLayout } from "./AppLayout";

import { GlossaryEntryDetails } from "./GlossaryEntryDetails";
import { GlossaryEntry } from "../types";
import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";

const { Panel } = Collapse;
const { Link } = Anchor;
const { Search } = Input;
const { Title } = Typography;
var lastLetter = "";
var searchEmpty = false;

const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "#",
];

export const GlossaryRoute = React.memo(function GlossaryRoute() {
  const { glossaryEntries } = useAppState();
  const t = useTranslation();

  const [search, setSearch] = React.useState("");
  const [isAffix, setAffix] = React.useState(false);
  const [isAffixSearching, setAffixSearching] = React.useState(false);

  React.useEffect(() => {
    if (!isAffix && isAffixSearching) {
      setAffixSearching(false);
    }
  }, [isAffix, isAffixSearching]);

  const groups = React.useMemo(() => {
    const entries = !search
      ? glossaryEntries
      : glossaryEntries.filter((e) =>
          e.get("title")?.toLowerCase().includes(search.toLowerCase())
        );

    const groups: Record<string, GlossaryEntry[]> = Object.fromEntries(
      LETTERS.map((letter) => [letter, []])
    );

    for (const entry of entries) {
      const letter = entry.get("title").charAt(0).toUpperCase();

      if (LETTERS.includes(letter)) {
        groups[letter].push(entry);
      } else if (letter === "Ä") {
        groups["A"].push(entry);
      } else if (letter === "Ü") {
        groups["U"].push(entry);
      } else if (letter === "Ö") {
        groups["O"].push(entry);
      } else {
        groups["#"].push(entry);
      }
    }

    return Object.entries(groups);
  }, [glossaryEntries, search]);

  return (
    <AppLayout>
      {/* Page Header */}
      <div style={{ padding: "10px" }}>
        <Title level={3}>{t("app:glossar.title")}</Title>
      </div>

      {/* Nav & Search */}
      <Affix offsetTop={80} onChange={(x) => setAffix(x || false)}>
        <div
          style={{
            padding: "20px 10px",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",

            // TODO!? @Nico schön machen
            ...(isAffix && {
              background: "#f0f2f5",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }),
          }}
        >
          {!isAffixSearching && (
            <Anchor
              affix={false}
              offsetTop={220}
              style={{
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",

                ...(!isAffix && {
                  marginBottom: 20,
                }),
              }}
            >
              {LETTERS.map((X) => (
                <Link
                  title={X}
                  key={X}
                  href={"#" + (X !== "#" ? X : lastLetter)}
                >
                  &nbsp;
                </Link>
              ))}

              {isAffix && (
                <Button
                  type="text"
                  icon={<Icon icon="fa:search" />}
                  onClick={() => {
                    setAffixSearching(true);
                  }}
                />
              )}
            </Anchor>
          )}
          <div
            style={{
              display: !isAffix || isAffixSearching ? "block" : "none",
            }}
          >
            <Search
              id="searchglossar"
              placeholder={t("app:glossar.search")}
              size="large"
              allowClear
              style={{
                width: "calc(100% - 50px)",
                maxWidth: 700,
              }}
              onSearch={(value) => {
                setSearch(value);
              }}
              onChange={(e) => {
                if (e.target.value === "") {
                  setSearch("");
                }
              }}
              enterButton
            />

            {isAffixSearching && (
              <Button
                type="text"
                style={{
                  marginLeft: 10,
                }}
                icon={
                  <Icon
                    icon="fa:times"
                    style={{
                      fontSize: 32,
                    }}
                  />
                }
                onClick={() => {
                  setAffixSearching(false);
                }}
              />
            )}
          </div>
        </div>
      </Affix>

      {/* Content */}

      <div style={{ width: "100%", padding: "10px" }}>
        {searchEmpty ? (
          <div style={{ paddingTop: "50px" }}>
            <Empty
              imageStyle={{
                height: 100,
              }}
              description={<span>{t("app:glossar.noentry")}</span>}
            >
              <Button
                type="primary"
                onClick={() => {
                  setSearch("");
                }}
              >
                {t("app:glossar.resetsearch")}
              </Button>
            </Empty>
          </div>
        ) : (
          <Space id="test" direction="vertical" style={{ width: "100%" }}>
            {groups.map(([letter, glossaryEntries]) => (
              <div key={letter} id={letter}>
                {glossaryEntries.length > 0 && (
                  <>
                    <h1>{letter}</h1>
                    <Collapse
                      style={{ width: "100%", fontWeight: "bold" }}
                      // activeKey={glossaryEntries.map((e) => e.id)}
                    >
                      {glossaryEntries.map((glossaryEntry) => (
                        <Collapse.Panel
                          key={glossaryEntry.id}
                          header={
                            glossaryEntry.get("title").toUpperCase().charAt(0) +
                            " - " +
                            glossaryEntry.get("title")
                          }
                          children={
                            <GlossaryEntryDetails entry={glossaryEntry} />
                          }
                        />
                      ))}
                    </Collapse>
                  </>
                )}
              </div>
            ))}
          </Space>
        )}
      </div>
    </AppLayout>
  );
});
