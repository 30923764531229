import { BaseService, useServiceStore } from "@opendash/core";
import {
  changeLanguage,
  getCurrentLanguageSync,
  translate,
  translateSync,
} from "@opendash/i18n";

import Parse from "parse";

import {
  GlossaryEntry,
  Invoice,
  InvoicePage,
  Language,
  TenantConfig,
  TenantDomain,
  Video,
} from "./types";

import { DEFAULT_TENANT_ID } from "./const";

interface StateInterface {
  tenantId: string | null;
  tenantConfig: TenantConfig | undefined;
  invoices: Invoice[];
  invoicePages: InvoicePage[];
  glossaryEntries: GlossaryEntry[];
  glossaryEntriesMapById: Record<string, GlossaryEntry>;
  glossaryEntriesMapByKeyword: Record<string, GlossaryEntry>;
  gloassryEntriesMapByTag: Record<string, GlossaryEntry>;
  videos: Video[];
  frontpageImage: string | undefined;
  forceLanguage: string | undefined;
  embedded: boolean;
}

export class AppService extends BaseService<StateInterface> {
  error: boolean = false;
  errorTenantNotFound: boolean = false;

  constructor() {
    super({
      initialState: {
        tenantId: null,
        tenantConfig: undefined,
        invoices: [],
        invoicePages: [],
        glossaryEntries: [],
        glossaryEntriesMapById: {},
        glossaryEntriesMapByKeyword: {},
        gloassryEntriesMapByTag: {},
        videos: [],
        frontpageImage: undefined,
        forceLanguage: undefined,
        embedded: false,
      },
    });
  }

  public async init() {
    try {
      const tenantDomain = await new Parse.Query(TenantDomain)
        .equalTo("domain", window?.location?.hostname)
        .first();

      const tenant = tenantDomain?.get("tenant");
      const tenantId = tenant?.id;

      if (!tenant || !tenantId) {
        this.error = true;
        this.errorTenantNotFound = true;
        this.setLoading(false);

        return;
      }

      const tenantConfig = await new Parse.Query(TenantConfig)
        .equalTo("tenant", tenant)
        .first();

      if (
        tenantConfig?.get("forceHost") &&
        tenantConfig?.get("forceHost") !== window?.location?.hostname
      ) {
        window.location.href = `https://${tenantConfig?.get("forceHost")}${
          window?.location?.pathname
        }`;

        return;
      }

      const forceLanguage = tenantConfig?.get("forceLanguage")?.id;
      const currentLanguageId = forceLanguage || getCurrentLanguageSync();

      if (forceLanguage && forceLanguage !== getCurrentLanguageSync()) {
        changeLanguage(forceLanguage);
      }

      const defaultTenant = new Parse.Object("OD3_Tenant", {
        id: DEFAULT_TENANT_ID,
      });

      console.log("current language", currentLanguageId);

      await translate("app:menu.frontpage");

      const frontpageImage = await preloadImage(
        "frontpage-image",
        tenantConfig?.get("frontpageBackgroundImage")?.url()
      );

      const currentLanguage = await new Parse.Query(Language).get(
        currentLanguageId
      );

      document.title = tenantConfig?.get("title") || "ASEW Rechnungserklärer";

      const primaryColor = tenantConfig?.get("primaryColor") || "#ff0000";
      const secondaryColor = tenantConfig?.get("secondaryColor") || "#ff0000";
      const primaryTextColor =
        tenantConfig?.get("primaryTextColor") || "#ff0000";
      const secondaryTextColor =
        tenantConfig?.get("secondaryTextColor") || "#ff0000";

      let root = document.documentElement;

      root.style.setProperty("--primaryColor", primaryColor);
      root.style.setProperty("--primaryTextColor", primaryTextColor);
      root.style.setProperty("--primaryColor-alpha", hexToRgbA(primaryColor));
      root.style.setProperty("--secondaryColor", secondaryColor);
      root.style.setProperty("--secondaryTextColor", secondaryTextColor);
      root.style.setProperty(
        "--secondaryColor-alpha",
        hexToRgbA(secondaryColor)
      );

      const invoices = await new Parse.Query(Invoice)
        .equalTo("tenant", tenant)
        .descending("order")
        .find();

      const invoicePages = await new Parse.Query(InvoicePage)
        .equalTo("tenant", tenant)
        .descending("order")
        .find();

      const defaultGlossaryEntries = await new Parse.Query(GlossaryEntry)
        .equalTo("tenant", defaultTenant)
        .equalTo("language", currentLanguage)
        .ascending("title")
        .limit(999999999999)
        .find();

      const customGlossaryEntries = await new Parse.Query(GlossaryEntry)
        .equalTo("tenant", tenant)
        .equalTo("language", currentLanguage)
        .ascending("title")
        .limit(999999999999)
        .find();

      const allGlossaryEntries = [
        ...defaultGlossaryEntries,
        ...customGlossaryEntries,
      ];

      const glossaryEntriesMapByKeyword: Record<string, GlossaryEntry> = {};

      for (const e of allGlossaryEntries) {
        glossaryEntriesMapByKeyword[e.get("keyword")] = e;
      }

      const glossaryEntries = Object.values(glossaryEntriesMapByKeyword);

      glossaryEntries.sort(function (a, b) {
        const keyA = a.get("title").toUpperCase();
        const keyB = b.get("title").toUpperCase();
        // Compare the 2 dates

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      const glossaryEntriesMapById: Record<string, GlossaryEntry> = {};
      const gloassryEntriesMapByTag: Record<string, GlossaryEntry> = {};

      for (const entry of glossaryEntries) {
        glossaryEntriesMapById[entry.id] = entry;

        gloassryEntriesMapByTag[entry.get("title")] = entry;

        for (const tag of entry.get("tags") as string[]) {
          gloassryEntriesMapByTag[tag] = entry;
        }
      }


      console.log("TEST",tenantConfig?.get("hideVideoIds"));
      let videos = await new Parse.Query(Video)
        .equalTo("tenant", tenant)
        // .equalTo("language", currentLanguage)
        .descending("order")
        .find();

      if (tenantId !== DEFAULT_TENANT_ID) {
        const defaultVideos = await new Parse.Query(Video)
          .equalTo("tenant", defaultTenant)
          // .equalTo("language", currentLanguage)
          .descending("order")
          .find();

        videos = [...videos, ...defaultVideos].sort(
          (a, b) => b.get("order") - a.get("order")
        );
      }
      videos = videos.filter(video => !tenantConfig?.hideVideoIds.includes(video.id));



/*
      if (tenantId === "JgpCYeVWXc") {
        videos = videos.filter((v) => v.id !== "KTQB3RolhA");
        videos = videos.filter((v) => v.id !== "ZuEm0R5DQP");
        videos = videos.filter((v) => v.id !== "GeGrDaCY97");
      }

      if (tenantId === "aGZR6AgRdt") {
        videos = videos.filter((v) => v.id !== "PDRzhtk8FB");
      }

      if (tenantId === "gywDzRgrKk") {
        videos = videos.filter((v) => v.id !== "J5oc9s1BA9");
      }

      if (tenantId === "aoBQ6s98Kl") {
        videos = videos.filter((v) => v.id !== "J5oc9s1BA9");
        videos = videos.filter((v) => v.id !== "PmMkUiNmtL");
        videos = videos.filter((v) => v.id !== "vMBmhUZYk1");
        videos = videos.filter((v) => v.id !== "lZggfAg8OC");
        videos = videos.filter((v) => v.id !== "ZuEm0R5DQP");
        videos = videos.filter((v) => v.id !== "7iCB2AEMgH");
        videos = videos.filter((v) => v.id !== "IBGFIfCkCP");
      }
*/
      this.store.update((draft) => {
        draft.tenantId = tenantId;
        draft.tenantConfig = tenantConfig;
        draft.invoices = invoices;
        draft.invoicePages = invoicePages;
        draft.glossaryEntries = glossaryEntries;
        draft.glossaryEntriesMapById = glossaryEntriesMapById;
        draft.glossaryEntriesMapByKeyword = glossaryEntriesMapByKeyword;
        draft.gloassryEntriesMapByTag = gloassryEntriesMapByTag;
        draft.videos = videos;
        draft.frontpageImage = frontpageImage;
        draft.forceLanguage = forceLanguage;
      });

      this.setLoading(false);

      console.log("init Rechnungserklärer");
      console.log("tenant.id =", tenantId);
      console.log("tenantConfig.id =", tenantConfig!.id);
      console.log("parentTenant.id =", DEFAULT_TENANT_ID);
    } catch (error) {
      console.error(error);

      this.error = true;
      this.setLoading(false);
    } finally {
      window.localStorage.removeItem("Parse/asew/installationId");
    }
  }
}

export const app = new AppService();

const defaultSelector = (state: StateInterface) => state;

export function useAppState(): StateInterface;
export function useAppState<T = any>(selector: (state: StateInterface) => T): T;
export function useAppState<T = any>(selector?: (state: StateInterface) => T) {
  if (selector) {
    return useServiceStore(app, selector);
  } else {
    return useServiceStore(app, defaultSelector) as any as StateInterface;
  }
}

export function getTitle(title: string | undefined) {
  if (getCurrentLanguageSync() !== "de") {
    switch (title) {
      case "Strom":
      case "Stromrechnung":
      case "Ökostrom":
      case "Ökostromrechnung":
        return translateSync("app:invoice.electricity");
      case "Gas":
      case "Erdgas":
      case "Gasrechnung":
      case "Erdgasrechnung":
      case "Ökogas":
      case "Ökogasrechnung":
        return translateSync("app:invoice.gas");
      case "Wasser":
      case "Wasserrechnung":
      case "Trinkwasser":
        return translateSync("app:invoice.water");
      case "Fernwärme":
      case "Fernwärmerechnung":
        return translateSync("app:invoice.remoteheating");
      case "Fernwärme (Kamen)":
        return translateSync("app:invoice.remoteheatingkamen");
      case "Fernwärme (Bergkamen)":
        return translateSync("app:invoice.remoteheatingbergkamen");
      case "Gesamt":
      case "Gesamtrechnung":
      case "Energie":
      case "Energierechnung":
      case "Jahresrechnung":
      case "Strom, Gas, Wasser":
      case "Strom, Gas & Wasser":
      case "Energie- und Wasserrechnung":
        return translateSync("app:invoice.energy");
      case "Gesamt":
      case "Gesamtrechnung":
      case "Energie":
      case "Energierechnung":
        return translateSync("app:invoice.energy");
      case "Übersicht":
        return translateSync("app:invoice.overview");
      case "Strom- und Gaspreisbremse":
        return translateSync("app:invoice.pricecap");
      case "Strom (Eintarif)":
      case "Strom (ET)":
      case "Stromrechnung (Eintarif)":
      case "Stromrechnung (ET)":
        return translateSync("app:invoice.electricitysingle");
      case "Strom (Zweitarif)":
      case "Strom (ZT)":
      case "Strom (HT/NT)":
      case "Stromrechnung (Zweitarif)":
      case "Stromrechnung (ZT)":
      case "Stromrechnung (HT/NT)":
        return translateSync("app:invoice.electricitydual");
      case "Abwasser":
      case "Abwasserrechnung":
        return translateSync("app:invoice.wastewater");
    }
  }

  return title || "";
}

export function getInvoiceTitle(invoice: Invoice) {
  const title = invoice.get("title");

  return getTitle(title);
}

function hexToRgbA(hex: string) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c: string[] = hex.substring(1).split("");

    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    const r = parseInt(c[0] + [1], 16);
    const g = parseInt(c[2] + [3], 16);
    const b = parseInt(c[4] + [5], 16);

    return "rgba(" + [r, g, b].join(",") + ",0.75)";
  }

  return hex;
}

async function preloadImage(key: string, src: string | undefined) {
  if (!src) {
    return undefined;
  }

  const response = await fetch(src);

  const blob = await response.blob();

  return URL.createObjectURL(blob);
}
