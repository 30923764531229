import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface TenantDomainAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  domain: string;
  tenant: OD3_Tenant;
}

export class TenantDomain extends Parse.Object<TenantDomainAttributes> {
  static className: string = "APP_TenantDomain";

  constructor(data?: Partial<TenantDomainAttributes>) {
    super("APP_TenantDomain", data as TenantDomainAttributes);
  }

  get domain(): string {
    return super.get("domain");
  }
  set domain(value: string) {
    super.set("domain", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("APP_TenantDomain", TenantDomain);
