import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useNavigate, useParams } from "@opendash/router";
import { Modal, Typography, message, Card, Row, Col } from "antd";

import { useAppState } from "../state";
import { AppLayout } from "./AppLayout";
import { useTranslation } from "@opendash/i18n";

const { Meta } = Card;
const { Title } = Typography;

const ReactPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const VideoRoute = React.memo(function VideoRoute() {
  const t = useTranslation();
  const { videos } = useAppState();

  const { id } = useParams();
  const navigate = useNavigate();

  const selectedVideo = videos.find((video) => video.id === id);

  React.useEffect(() => {
    if (id && !selectedVideo) {
      message.error(t("app:videos.error_not_found"));
    }
  }, [id, selectedVideo]);

  return (
    <AppLayout>
      <>
        {/* Page Header */}
        <div style={{ padding: "10px" }}>
          <Title level={3}>{t("app:movies.title")}</Title>
        </div>
        {/* List of videos: */}
        <div style={{ padding: "0px" }}>
          <Row>
            {videos.map((video) => (
              <Col
                key={video.id}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                style={{ padding: "10px" }}
              >
                <Card
                  hoverable
                  key={video.id}
                  style={{ width: "100%", height: "340px" }}
                  onClick={() => {
                    navigate(`/rechnungserklaerer/filme/${video.id}`);
                  }}
                  cover={
                    <img
                      style={{ height: "213px" }}
                      src={video.get("image") ? video.get("image")?.url() : ""}
                    ></img>
                  }
                >
                  <Meta
                    title={
                      <h4 style={{ fontSize: "15px" }}>
                        {video.get("title")}&lrm;
                      </h4>
                    }
                    description={
                      <p style={{ fontSize: "13px" }}>
                        {video.get("description")}&lrm;
                      </p>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        {/* Show modal of selected video */}
        <Modal
          visible={!!selectedVideo}
          title={selectedVideo?.get("title")}
          width={1280}
          style={{ padding: 0, top: 100 }}
          destroyOnClose={true}
          footer={null}
          onCancel={() => {
            navigate(`/rechnungserklaerer/filme`);
          }}
        >
          {selectedVideo && (
            <>
              <ReactPlayerContainer>
                <ReactPlayer
                  controls
                  playing={true}
                  volume={1}
                  muted={true}
                  width="100%"
                  height="100%"
                  url={selectedVideo.get("video").url()}
                />
              </ReactPlayerContainer>

              <div style={{ padding: 24 }}>
                <ReactMarkdown
                  children={
                    selectedVideo.get("text")
                      ? selectedVideo.get("text") + "&lrm;"
                      : ""
                  }
                />
              </div>
            </>
          )}
        </Modal>
      </>
    </AppLayout>
  );
});
