import Parse from "parse";

import type { Language } from "./Language";

type OD3_Tenant = Parse.Object;

export interface TenantConfigAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  addressText?: string;
  contactEmail?: string;
  contactFax?: string;
  contactImage?: Parse.File;
  contactPhone?: string;
  contactText?: string;
  contactWeb?: string;
  contactWhatsappLabel?: string;
  contactWhatsappLink?: string;
  documentLabels: boolean;
  forceHost?: string;
  forceLanguage?: Language;
  frontpageBackgroundImage?: Parse.File;
  frontpageContactIcon?: string;
  frontpageGlossarIcon?: string;
  frontpageVideoIcon?: string;
  googleMaps?: string;
  hideFrontpageSecondRow: boolean;
  hideVideos: boolean;
  imprintText?: string;
  imprintUrl?: string;
  logo?: Parse.File;
  logoUrl?: string;
  mapGeoPoint?: Parse.GeoPoint;
  mapLabel?: string;
  menuItem2Icon?: string;
  menuItem2Label?: string;
  menuItem2Url?: string;
  menuItem3Icon?: string;
  menuItem3Label?: string;
  menuItem3Url?: string;
  menuItemIcon?: string;
  menuItemLabel?: string;
  menuItemUrl?: string;
  openingHoursText?: string;
  pageTitleContact?: string;
  pageTitleGlossary?: string;
  pageTitleInvoice?: string;
  pageTitleVideo?: string;
  primaryColor?: string;
  primaryTextColor?: string;
  privacyText?: string;
  privacyUrl?: string;
  routeDescriptionText?: string;
  secondaryColor?: string;
  secondaryTextColor?: string;
  tenant: OD3_Tenant;
  title?: string;
  tosText?: string;
  tosUrl?: string;
  hideVideoIds?: string[];
}

export class TenantConfig extends Parse.Object<TenantConfigAttributes> {
  static className: string = "APP_TenantConfig";

  constructor(data?: Partial<TenantConfigAttributes>) {
    super("APP_TenantConfig", data as TenantConfigAttributes);
  }

  get addressText(): string | undefined {
    return super.get("addressText");
  }
  set addressText(value: string | undefined) {
    super.set("addressText", value);
  }
  get contactEmail(): string | undefined {
    return super.get("contactEmail");
  }
  set contactEmail(value: string | undefined) {
    super.set("contactEmail", value);
  }
  get contactFax(): string | undefined {
    return super.get("contactFax");
  }
  set contactFax(value: string | undefined) {
    super.set("contactFax", value);
  }
  get contactImage(): Parse.File | undefined {
    return super.get("contactImage");
  }
  set contactImage(value: Parse.File | undefined) {
    super.set("contactImage", value);
  }
  get contactPhone(): string | undefined {
    return super.get("contactPhone");
  }
  set contactPhone(value: string | undefined) {
    super.set("contactPhone", value);
  }
  get contactText(): string | undefined {
    return super.get("contactText");
  }
  set contactText(value: string | undefined) {
    super.set("contactText", value);
  }
  get contactWeb(): string | undefined {
    return super.get("contactWeb");
  }
  set contactWeb(value: string | undefined) {
    super.set("contactWeb", value);
  }
  get contactWhatsappLabel(): string | undefined {
    return super.get("contactWhatsappLabel");
  }
  set contactWhatsappLabel(value: string | undefined) {
    super.set("contactWhatsappLabel", value);
  }
  get contactWhatsappLink(): string | undefined {
    return super.get("contactWhatsappLink");
  }
  set contactWhatsappLink(value: string | undefined) {
    super.set("contactWhatsappLink", value);
  }
  get documentLabels(): boolean {
    return super.get("documentLabels");
  }
  set documentLabels(value: boolean) {
    super.set("documentLabels", value);
  }
  get forceHost(): string | undefined {
    return super.get("forceHost");
  }
  set forceHost(value: string | undefined) {
    super.set("forceHost", value);
  }
  get forceLanguage(): Language | undefined {
    return super.get("forceLanguage");
  }
  set forceLanguage(value: Language | undefined) {
    super.set("forceLanguage", value);
  }
  get frontpageBackgroundImage(): Parse.File | undefined {
    return super.get("frontpageBackgroundImage");
  }
  set frontpageBackgroundImage(value: Parse.File | undefined) {
    super.set("frontpageBackgroundImage", value);
  }
  get frontpageContactIcon(): string | undefined {
    return super.get("frontpageContactIcon");
  }
  set frontpageContactIcon(value: string | undefined) {
    super.set("frontpageContactIcon", value);
  }
  get frontpageGlossarIcon(): string | undefined {
    return super.get("frontpageGlossarIcon");
  }
  set frontpageGlossarIcon(value: string | undefined) {
    super.set("frontpageGlossarIcon", value);
  }
  get frontpageVideoIcon(): string | undefined {
    return super.get("frontpageVideoIcon");
  }
  set frontpageVideoIcon(value: string | undefined) {
    super.set("frontpageVideoIcon", value);
  }
  get googleMaps(): string | undefined {
    return super.get("googleMaps");
  }
  set googleMaps(value: string | undefined) {
    super.set("googleMaps", value);
  }
  get hideFrontpageSecondRow(): boolean {
    return super.get("hideFrontpageSecondRow");
  }
  set hideFrontpageSecondRow(value: boolean) {
    super.set("hideFrontpageSecondRow", value);
  }
  get hideVideos(): boolean {
    return super.get("hideVideos");
  }
  set hideVideos(value: boolean) {
    super.set("hideVideos", value);
  }
  get imprintText(): string | undefined {
    return super.get("imprintText");
  }
  set imprintText(value: string | undefined) {
    super.set("imprintText", value);
  }
  get imprintUrl(): string | undefined {
    return super.get("imprintUrl");
  }
  set imprintUrl(value: string | undefined) {
    super.set("imprintUrl", value);
  }
  get logo(): Parse.File | undefined {
    return super.get("logo");
  }
  set logo(value: Parse.File | undefined) {
    super.set("logo", value);
  }
  get logoUrl(): string | undefined {
    return super.get("logoUrl");
  }
  set logoUrl(value: string | undefined) {
    super.set("logoUrl", value);
  }
  get mapGeoPoint(): Parse.GeoPoint | undefined {
    return super.get("mapGeoPoint");
  }
  set mapGeoPoint(value: Parse.GeoPoint | undefined) {
    super.set("mapGeoPoint", value);
  }
  get mapLabel(): string | undefined {
    return super.get("mapLabel");
  }
  set mapLabel(value: string | undefined) {
    super.set("mapLabel", value);
  }
  get menuItem2Icon(): string | undefined {
    return super.get("menuItem2Icon");
  }
  set menuItem2Icon(value: string | undefined) {
    super.set("menuItem2Icon", value);
  }
  get menuItem2Label(): string | undefined {
    return super.get("menuItem2Label");
  }
  set menuItem2Label(value: string | undefined) {
    super.set("menuItem2Label", value);
  }
  get menuItem2Url(): string | undefined {
    return super.get("menuItem2Url");
  }
  set menuItem2Url(value: string | undefined) {
    super.set("menuItem2Url", value);
  }
  get menuItem3Icon(): string | undefined {
    return super.get("menuItem3Icon");
  }
  set menuItem3Icon(value: string | undefined) {
    super.set("menuItem3Icon", value);
  }
  get menuItem3Label(): string | undefined {
    return super.get("menuItem3Label");
  }
  set menuItem3Label(value: string | undefined) {
    super.set("menuItem3Label", value);
  }
  get menuItem3Url(): string | undefined {
    return super.get("menuItem3Url");
  }
  set menuItem3Url(value: string | undefined) {
    super.set("menuItem3Url", value);
  }
  get menuItemIcon(): string | undefined {
    return super.get("menuItemIcon");
  }
  set menuItemIcon(value: string | undefined) {
    super.set("menuItemIcon", value);
  }
  get menuItemLabel(): string | undefined {
    return super.get("menuItemLabel");
  }
  set menuItemLabel(value: string | undefined) {
    super.set("menuItemLabel", value);
  }
  get menuItemUrl(): string | undefined {
    return super.get("menuItemUrl");
  }
  set menuItemUrl(value: string | undefined) {
    super.set("menuItemUrl", value);
  }
  get openingHoursText(): string | undefined {
    return super.get("openingHoursText");
  }
  set openingHoursText(value: string | undefined) {
    super.set("openingHoursText", value);
  }
  get pageTitleContact(): string | undefined {
    return super.get("pageTitleContact");
  }
  set pageTitleContact(value: string | undefined) {
    super.set("pageTitleContact", value);
  }
  get pageTitleGlossary(): string | undefined {
    return super.get("pageTitleGlossary");
  }
  set pageTitleGlossary(value: string | undefined) {
    super.set("pageTitleGlossary", value);
  }
  get pageTitleInvoice(): string | undefined {
    return super.get("pageTitleInvoice");
  }
  set pageTitleInvoice(value: string | undefined) {
    super.set("pageTitleInvoice", value);
  }
  get pageTitleVideo(): string | undefined {
    return super.get("pageTitleVideo");
  }
  set pageTitleVideo(value: string | undefined) {
    super.set("pageTitleVideo", value);
  }
  get primaryColor(): string | undefined {
    return super.get("primaryColor");
  }
  set primaryColor(value: string | undefined) {
    super.set("primaryColor", value);
  }
  get primaryTextColor(): string | undefined {
    return super.get("primaryTextColor");
  }
  set primaryTextColor(value: string | undefined) {
    super.set("primaryTextColor", value);
  }
  get privacyText(): string | undefined {
    return super.get("privacyText");
  }
  set privacyText(value: string | undefined) {
    super.set("privacyText", value);
  }
  get privacyUrl(): string | undefined {
    return super.get("privacyUrl");
  }
  set privacyUrl(value: string | undefined) {
    super.set("privacyUrl", value);
  }
  get routeDescriptionText(): string | undefined {
    return super.get("routeDescriptionText");
  }
  set routeDescriptionText(value: string | undefined) {
    super.set("routeDescriptionText", value);
  }
  get secondaryColor(): string | undefined {
    return super.get("secondaryColor");
  }
  set secondaryColor(value: string | undefined) {
    super.set("secondaryColor", value);
  }
  get secondaryTextColor(): string | undefined {
    return super.get("secondaryTextColor");
  }
  set secondaryTextColor(value: string | undefined) {
    super.set("secondaryTextColor", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get title(): string | undefined {
    return super.get("title");
  }
  set title(value: string | undefined) {
    super.set("title", value);
  }
  get tosText(): string | undefined {
    return super.get("tosText");
  }
  set tosText(value: string | undefined) {
    super.set("tosText", value);
  }
  get tosUrl(): string | undefined {
    return super.get("tosUrl");
  }
  set tosUrl(value: string | undefined) {
    super.set("tosUrl", value);
  }
  get hideVideoIds(): string[] {
    return super.get("hideVideoIds") || [];
  }
  set hideVideoIds(value: string[]) {
    super.set("hideVideoIds", value);
  }



}

Parse.Object.registerSubclass("APP_TenantConfig", TenantConfig);
