import * as React from "react";

import { useNavigate } from "@opendash/router";
import { getInvoiceTitle, getTitle, useAppState } from "../state";

import { Col, Row } from "antd";

import { useTranslation } from "@opendash/i18n";
import { Icon } from "@opendash/icons";
import styled from "styled-components";
import { AppLayout } from "./AppLayout";
import { InvoiceIcon } from "./InvoiceIcon";

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 800px) {
    padding-top: 7px;
    padding-bottom: 5px;
  }
`;

export const FrontpageRoute = React.memo(function FrontpageRoute() {
  const app = useAppState();
  const navigate = useNavigate();
  const t = useTranslation();

  const { invoices, invoicePages: invoicePagesAll } = useAppState();
  const { tenantConfig } = useAppState();

  const background = app.frontpageImage;

  return (
    <AppLayout fullWidth>
      <OuterContainer
        style={
          background
            ? {
                backgroundImage: `url("${background}")`,
              }
            : undefined
        }
      >
        <div>
          <Row align="middle" justify="center" style={{ width: "100%" }}>
            {invoices.map((invoice) => {
              return (
                <div
                  className="frontboxouter"
                  key={invoice.id}
                  style={{
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="frontpageboxbig"
                    onClick={() => {
                      navigate(`/rechnungserklaerer/rechnung/` + invoice.id);
                    }}
                  >
                    <Row>
                      <Col xs={24}>
                        <InvoiceIcon invoice={invoice} size="50px" />
                      </Col>
                      <Col xs={24}>
                        <p
                          style={{
                            fontSize: "24px",
                          }}
                        >
                          {getInvoiceTitle(invoice)}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </Row>
          {!tenantConfig?.get("hideFrontpageSecondRow") && (
            <Row align="middle" justify="center" style={{}}>
              <div
                className="frontboxouter"
                style={{
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="frontpagebox"
                  onClick={() => {
                    navigate(`/rechnungserklaerer/glossar`);
                  }}
                >
                  <Row>
                    <Col xs={24}>
                      <Icon
                        style={{
                          fontSize: "40px",
                        }}
                        icon={
                          tenantConfig?.get("frontpageGlossarIcon") || "fa:text"
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <p
                        style={{
                          fontSize: "22px",
                        }}
                      >
                        {t("app:frontpage.glossar")}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>

              {!tenantConfig?.get("hideVideos") && (
                <div
                  className="frontboxouter"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    className="frontpagebox"
                    onClick={() => {
                      navigate(`/rechnungserklaerer/filme`);
                    }}
                  >
                    <Row>
                      <Col xs={24}>
                        <Icon
                          style={{
                            fontSize: "40px",
                          }}
                          icon={
                            tenantConfig?.get("frontpageVideoIcon") ||
                            "fa:video"
                          }
                        />
                      </Col>
                      <Col xs={24}>
                        <p
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          {t("app:frontpage.movies")}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {tenantConfig?.get("menuItemLabel") && (
                <div
                  className="frontboxouter"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    className="frontpagebox"
                    onClick={() => {
                      window
                        ?.open(tenantConfig?.get("menuItemUrl"), "_blank")
                        ?.focus();
                    }}
                  >
                    <Row>
                      <Col xs={24}>
                        <Icon
                          style={{
                            fontSize: "40px",
                          }}
                          icon={tenantConfig?.get("menuItemIcon") || "fa:link"}
                        />
                      </Col>
                      <Col xs={24}>
                        <p
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          {getTitle(tenantConfig?.get("menuItemLabel"))}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {tenantConfig?.get("menuItem2Label") && (
                <div
                  className="frontboxouter"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    className="frontpagebox"
                    onClick={() => {
                      window
                        ?.open(tenantConfig?.get("menuItem2Url"), "_blank")
                        ?.focus();
                    }}
                  >
                    <Row>
                      <Col xs={24}>
                        <Icon
                          style={{
                            fontSize: "40px",
                          }}
                          icon={tenantConfig?.get("menuItem2Icon") || "fa:link"}
                        />
                      </Col>
                      <Col xs={24}>
                        <p
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          {getTitle(tenantConfig?.get("menuItem2Label"))}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              {tenantConfig?.get("menuItem3Label") && (
                <div
                  className="frontboxouter"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <div
                    className="frontpagebox"
                    onClick={() => {
                      window
                        ?.open(tenantConfig?.get("menuItem3Url"), "_blank")
                        ?.focus();
                    }}
                  >
                    <Row>
                      <Col xs={24}>
                        <Icon
                          style={{
                            fontSize: "40px",
                          }}
                          icon={tenantConfig?.get("menuItem3Icon") || "fa:link"}
                        />
                      </Col>
                      <Col xs={24}>
                        <p
                          style={{
                            fontSize: "22px",
                          }}
                        >
                          {getTitle(tenantConfig?.get("menuItem3Label"))}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              <div
                className="frontboxouter"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <div
                  className="frontpagebox"
                  onClick={() => {
                    navigate(`/rechnungserklaerer/contact`);
                  }}
                >
                  <Row>
                    <Col xs={24}>
                      <Icon
                        style={{
                          fontSize: "40px",
                        }}
                        icon={
                          tenantConfig?.get("frontpageContactIcon") ||
                          "fa:address-book"
                        }
                      />
                    </Col>
                    <Col xs={24}>
                      <p
                        style={{
                          fontSize: "22px",
                        }}
                      >
                        {t("app:frontpage.contact")}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          )}
        </div>
      </OuterContainer>
    </AppLayout>
  );
});
