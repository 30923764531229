import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useNavigate, useParams } from "@opendash/router";
import { Modal, Typography, message, Card, Row, Col } from "antd";

import { useAppState } from "../state";
import { AppLayout } from "./AppLayout";
import { useTranslation } from "@opendash/i18n";
import { TenantConfigAttributes } from "../types";

const Container = styled.div`
  background: white;
  padding: 20px;

  min-height: 100%;
`;

const Title = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  margin-bottom: 20px;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
`;

export const LegalRoute = React.memo<{ scope: keyof TenantConfigAttributes }>(
  function LegalRoute({ scope }) {
    const t = useTranslation();
    const { tenantConfig } = useAppState();

    return (
      <AppLayout fullWidth>
        <Container>
          <Title>
            <Typography.Title
              level={3}
              children={t("app:legal.title", { context: scope })}
            />
          </Title>
          <Content>
            <ReactMarkdown
              children={(tenantConfig?.get(scope) as string) || ""}
            />
          </Content>
        </Container>
      </AppLayout>
    );
  }
);
