import * as React from "react";
import { useAppState } from "../state";
import styled from "styled-components";

const Logo = styled.img`
  max-height: 70px;
  padding: 7px 0;
  margin-top: 5px;
  @media only screen and (max-width: 1240px) {
    padding: 7px 0;
    max-height: 45px;
    margin-top: 20px;
  }
`;

export const HeaderLogoComponent = React.memo(function HeaderLogoComponent() {
  const { tenantConfig } = useAppState();

  return (
    <Logo
      style={{
        height: "100%",
        float: "right",
        cursor: "pointer",
      }}
      onClick={() => {
        if (tenantConfig?.get("logoUrl")) {
          window.open(tenantConfig?.get("logoUrl"), "_blank")?.focus();
        }
      }}
      src={tenantConfig?.get("logo")?.url() || ""}
    />
  );
});
