window.localStorage.removeItem("Parse/asew/installationId");
import "./parse.config";
import "antd/dist/reset.css";
import { ComponentType } from "react";

import { AppFactory, init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { LocalUserAdapter } from "./adapter/LocalUserAdapter";
import { LocalNavigationAdapter } from "./adapter/LocalNavigationAdapter";

import { ContactRoute } from "./components/ContactRoute";
import { EnergySavingTipsRoute } from "./components/EnergySavingTipsRoute";
import { FrontpageRoute } from "./components/FrontpageRoute";
import { GlossaryRoute } from "./components/GlossaryRoute";
import { InvoiceRoute } from "./components/InvoiceRoute";
import { VideoRoute } from "./components/VideoRoute";

import { app } from "./state";

import { changeLanguage, onLanguageChange } from "@opendash/i18n";
import { AppProvider } from "./components/AppProvider";
import { LegalRoute } from "./components/LegalRoute";
import { LocalSourceAdapter } from "./adapter/LocalSourceAdapter";


init("opendash", async (factory: AppFactory) => {
  // Logo:
  // factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);
  // factory.ui.setLogoImage(require("../assets/logo.png"));
  factory.ui.disableHeader();
  factory.ui.disableFooter();

  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/asew-default.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", undefined, true);
  factory.registerLanguage("tr", "Türkisch", "en");
  factory.registerLanguage("ru", "Russisch", "en");
  factory.registerLanguage("ro", "Rumänisch", "en");
  factory.registerLanguage("bg", "Bulgarisch", "en");
  factory.registerLanguage("uk", "Ukrainisch", "en");
  factory.registerLanguage("ar", "Arabisch", "en");

  factory.registerTranslationResolver(
    "en",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/en.json")
  );

  factory.registerTranslationResolver(
    "de",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "tr",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/tr.json")
  );

  factory.registerTranslationResolver(
    "ru",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/ru.json")
  );

  factory.registerTranslationResolver(
    "ro",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/ro.json")
  );

  factory.registerTranslationResolver(
    "bg",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/bg.json")
  );

  factory.registerTranslationResolver(
    "uk",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/uk.json")
  );

  factory.registerTranslationResolver(
    "ar",
    "app",

    // @ts-ignore
    async (language) => await import("./translations/ar.json")
  );

  // Adapter + Plugins
  factory.registerDeviceStorageAdapter(new StorageAdapterLS({ scope: "dev" }));
  factory.registerUserStorageAdapter(new StorageAdapterLS({ scope: "user" }));
  factory.registerUserAdapter(new LocalUserAdapter());
  factory.registerNavigationAdapter(new LocalNavigationAdapter());
  factory.registerSourceAdapter(
    new LocalSourceAdapter({
      sources: [
        {
          id: "ASEW",
          tag: "asew",
          name: "ASEW",
          meta: {},
          parent: null,
        },
        {
          id: "ASEW-categories",
          tag: "ASEW-categories",
          name: "ASEW (Categories)",
          meta: {},
          parent: null,
        },
      ],
    })
  );

  // Frontpage
  const routes = [
    {
      label: "Frontpage",
      path: "/rechnungserklaerer/start",
      icon: "fa:file-pdf",
    },
    {
      label: "Rechnungen",
      path: "/rechnungserklaerer/rechnung",
      icon: "fa:file-pdf",
    },
    {
      label: "Glossar",
      path: "/rechnungserklaerer/glossar",
      icon: "fa:book",
    },
    {
      label: "Filme",
      path: "/rechnungserklaerer/filme",
      icon: "fa:video",
    },
    {
      label: "Kontakt",
      path: "/rechnungserklaerer/contact",
      icon: "fa:address-book",
    },
    {
      label: "Individuell",
      path: "/rechnungserklaerer/Individuell",
    },
  ];

  for (const route of routes) {
    factory.registerStaticNavigationItem({
      id: route.path,
      group: "frontpage",
      place: "frontpage",
      label: route.label,
      icon: route.icon ? route.icon : "fa:empty-set",
      order: 10,
      color: "#ffe000",
      link: route.path,
      routeCondition: "**",
      activeCondition: "**",
    });
  }

  factory.registerProviderComponent(AppProvider);

  // Routing

  factory.registerRoute({
    path: "/",
    //redirectPath: "/rechnungserklaerer/rechnung",
    component: makeAsync(FrontpageRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung/:invoiceId",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/rechnung/:invoiceId/:pageId",
    component: makeAsync(InvoiceRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/filme",
    component: makeAsync(VideoRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/filme/:id",
    component: makeAsync(VideoRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/glossar",
    component: makeAsync(GlossaryRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/glossar/:id",
    component: makeAsync(GlossaryRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/contact",
    component: makeAsync(ContactRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/kontakt",
    component: makeAsync(ContactRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/energy-saving-tips",
    component: makeAsync(EnergySavingTipsRoute),
  });

  factory.registerRoute({
    path: "/rechnungserklaerer/energiespartipps",
    component: makeAsync(EnergySavingTipsRoute),
  });

  factory.registerRoute({
    path: "/impressum",
    component: makeAsync(LegalRoute),
    props: {
      scope: "imprintText",
    },
  });

  factory.registerRoute({
    path: "/agb",
    component: makeAsync(LegalRoute),
    props: {
      scope: "tosText",
    },
  });

  factory.registerRoute({
    path: "/datenschutz",
    component: makeAsync(LegalRoute),
    props: {
      scope: "privacyText",
    },
  });
})
  .then(async () => {
    console.log("init open.DASH");

    const savedLanguage = window.localStorage.getItem(
      "rechnungserklärer/language"
    );

    if (savedLanguage) {
      if(savedLanguage === "ar") {
        document.documentElement.lang = "ar";
        document.documentElement.dir = "rtl";
      } else {
        document.documentElement.lang = "en";
              document.documentElement.dir = "ltr";
      }
      await changeLanguage(savedLanguage);
    }

    onLanguageChange((language) => {
      window.localStorage.setItem("rechnungserklärer/language", language);
    });

    await app.init();
  })
  .catch((error) => {
    console.error("opendash.init error", error);
  });

function makeAsync(comp: ComponentType) {
  return async () => ({ default: comp });
}
