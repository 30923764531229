import { Icon } from "@opendash/icons";
import * as React from "react";

interface Props {
  size: string;
  invoice: Parse.Object;
}

const icon = require("../../assets/invoice-fernwaerme.png");

export const InvoiceIcon = React.memo<Props>(function InvoiceIcon({
  invoice,
  size,
}) {
  // if (invoice.id === "LwADNYouRa") {
  //   return <img src={icon} width={size} height={size} />;
  // }

  return (
    <Icon
      style={{
        fontSize: size,
      }}
      icon={invoice.get("icon") || "fa:file"}
    />
  );
});
