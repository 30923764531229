import * as React from "react";

import { Affix, Avatar, Tooltip, Modal, Button, Space } from "antd";
import { Icon } from "@opendash/icons";
import { ContactContent } from "./ContactContent";
import { LanguageControls } from "./LanguageControls";
import { useAppState } from "../state";
import { useTranslation } from "@opendash/i18n";
import { Link } from "@opendash/router";

interface Props {}

export const FooterComponent = React.memo<Props>(function FooterComponent({}) {
  const t = useTranslation();
  const state = useAppState();
  const [modalStatus, setModalStatus] = React.useState<boolean>(false);

  const savedLanguage = window.localStorage.getItem(
    "rechnungserklärer/language"
  );

  return (
    <>
      <Space direction="vertical">
        <LanguageControls />
        <Space direction="horizontal">
          {state.tenantConfig?.get("imprintUrl") ? (
            <a
              href={state.tenantConfig?.get("imprintUrl")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("app:footer.imprint")}
            </a>
          ) : state.tenantConfig?.get("imprintText") ? (
            <Link to="/impressum" children={t("app:footer.imprint")} />
          ) : null}

          {state.tenantConfig?.get("tosUrl") ? (
            <a
              href={state.tenantConfig?.get("tosUrl")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("app:footer.tos")}
            </a>
          ) : state.tenantConfig?.get("tosText") ? (
            <Link to="/agb" children={t("app:footer.tos")} />
          ) : null}

          {state.tenantConfig?.get("privacyUrl") ? (
            <a
              href={state.tenantConfig?.get("privacyUrl")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("app:footer.privacy")}
            </a>
          ) : state.tenantConfig?.get("privacyText") ? (
            <Link to="/datenschutz" children={t("app:footer.privacy")} />
          ) : null}
        </Space>
      </Space>

      <>
        <Affix
          style={{
            float: "right",
            position: "fixed",
            bottom: "30px",
            cursor: "pointer",
            ...(savedLanguage === "ar" ? { left: "40px" } : { right: "40px" }),
          }}
        >
          <Tooltip title="Kontakt">
            <Button
              type="text"
              onClick={() => {
                setModalStatus(true);
              }}
            >
              <Avatar
                size={{ xs: 40, sm: 55, md: 55, lg: 55, xl: 64, xxl: 64 }}
                className="contactaffix"
                icon={<Icon icon="fa:user-alt"></Icon>}
              />
            </Button>
          </Tooltip>
        </Affix>
      </>

      <Modal
        visible={modalStatus}
        title={""}
        width={800}
        bodyStyle={{ padding: 0 }}
        destroyOnClose={true}
        footer={null}
        onCancel={() => {
          setModalStatus(false);
        }}
      >
        <ContactContent />
      </Modal>
    </>
  );
});
