import Parse from "parse";

import type { Invoice } from "./Invoice";

type OD3_Tenant = Parse.Object;

export interface InvoicePageAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  annotations: any[];
  deleted: boolean;
  description?: string;
  draft: boolean;
  image?: Parse.File;
  imageHeight?: number;
  imageWidth?: number;
  invoice: Invoice;
  order: number;
  tenant: OD3_Tenant;
  title: string;
}

export class InvoicePage extends Parse.Object<InvoicePageAttributes> {
  static className: string = "APP_InvoicePage";

  constructor(data?: Partial<InvoicePageAttributes>) {
    super("APP_InvoicePage", data as InvoicePageAttributes);
  }

  get annotations(): any[] {
    return super.get("annotations");
  }
  set annotations(value: any[]) {
    super.set("annotations", value);
  }
  get deleted(): boolean {
    return super.get("deleted");
  }
  set deleted(value: boolean) {
    super.set("deleted", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get draft(): boolean {
    return super.get("draft");
  }
  set draft(value: boolean) {
    super.set("draft", value);
  }
  get image(): Parse.File | undefined {
    return super.get("image");
  }
  set image(value: Parse.File | undefined) {
    super.set("image", value);
  }
  get imageHeight(): number | undefined {
    return super.get("imageHeight");
  }
  set imageHeight(value: number | undefined) {
    super.set("imageHeight", value);
  }
  get imageWidth(): number | undefined {
    return super.get("imageWidth");
  }
  set imageWidth(value: number | undefined) {
    super.set("imageWidth", value);
  }
  get invoice(): Invoice {
    return super.get("invoice");
  }
  set invoice(value: Invoice) {
    super.set("invoice", value);
  }
  get order(): number {
    return super.get("order");
  }
  set order(value: number) {
    super.set("order", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
}

Parse.Object.registerSubclass("APP_InvoicePage", InvoicePage);
