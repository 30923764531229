import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface InvoiceAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  deleted: boolean;
  description?: string;
  draft: boolean;
  icon?: string;
  order: number;
  tenant: OD3_Tenant;
  title: string;
}

export class Invoice extends Parse.Object<InvoiceAttributes> {
  static className: string = "APP_Invoice";

  constructor(data?: Partial<InvoiceAttributes>) {
    super("APP_Invoice", data as InvoiceAttributes);
  }

  get deleted(): boolean {
    return super.get("deleted");
  }
  set deleted(value: boolean) {
    super.set("deleted", value);
  }
  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get draft(): boolean {
    return super.get("draft");
  }
  set draft(value: boolean) {
    super.set("draft", value);
  }
  get icon(): string | undefined {
    return super.get("icon");
  }
  set icon(value: string | undefined) {
    super.set("icon", value);
  }
  get order(): number {
    return super.get("order");
  }
  set order(value: number) {
    super.set("order", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
}

Parse.Object.registerSubclass("APP_Invoice", Invoice);
