import * as React from "react";

import styled from "styled-components";
import { app } from "../state";

import { Icon } from "@opendash/icons";
import { useOpenDashServices } from "@opendash/core";

const OuterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.div`
  max-width: 400px;
  text-align: center;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

const ErrorIcon = styled.div`
  font-size: 40px;
`;

const ErrorTitle = styled.div`
  font-size: 20px;
  font-weight: bold;

  margin-bottom: 10px;
`;

const ErrorDescription = styled.div`
  font-size: 16px;

  .highlight {
    background: rgba(0, 0, 0, 0.1);
    padding: 0 3px;
    border-radius: 3px;
  }
`;

export const AppProvider = React.memo(function AppProvider({ children }) {
  const [loading, setLoading] = React.useState(app.isLoading());

  React.useEffect(() => {
    return app.subscribe(() => {
      setLoading(app.isLoading());
    });
  }, []);

  if (app.errorTenantNotFound) {
    return (
      <OuterContainer>
        <InnerContainer>
          <ErrorIcon>
            <Icon icon="fa:exclamation-triangle" />
          </ErrorIcon>

          <ErrorTitle>Es ist ein Fehler aufgetreten</ErrorTitle>

          <ErrorDescription>
            Für die Domain{" "}
            <span className="highlight">{window?.location?.hostname}</span>{" "}
            wurde kein Mandant gefunden. Bitte kontaktieren Sie den Betreiber
            der Webseite.
          </ErrorDescription>
        </InnerContainer>
      </OuterContainer>
    );
  }

  if (app.error) {
    return (
      <OuterContainer>
        <InnerContainer>
          <ErrorIcon>
            <Icon icon="fa:exclamation-triangle" />
          </ErrorIcon>

          <ErrorTitle>Es ist ein Fehler aufgetreten</ErrorTitle>

          <ErrorDescription>
            Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es
            erneut. Falls dieser Fehler wiederholt auftritt, kontaktieren Sie
            den Betreiber der Webseite.
          </ErrorDescription>
        </InnerContainer>
      </OuterContainer>
    );
  }

  if (loading) {
    return (
      <OuterContainer>
        <InnerContainer>
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </InnerContainer>
      </OuterContainer>
    );
  }

  return <div style={{ height: "100%", display: "flex" }}>{children}</div>;
});
