import Parse from "parse";

import type { Language } from "./Language";

type OD3_Tenant = Parse.Object;

export interface GlossaryEntryAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  keyword: string;
  language: Language;
  markdown?: string;
  tags: any[];
  tenant: OD3_Tenant;
  text?: string;
  title: string;
}

export class GlossaryEntry extends Parse.Object<GlossaryEntryAttributes> {
  static className: string = "APP_GlossaryEntry";

  constructor(data?: Partial<GlossaryEntryAttributes>) {
    super("APP_GlossaryEntry", data as GlossaryEntryAttributes);
  }

  get keyword(): string {
    return super.get("keyword");
  }
  set keyword(value: string) {
    super.set("keyword", value);
  }
  get language(): Language {
    return super.get("language");
  }
  set language(value: Language) {
    super.set("language", value);
  }
  get markdown(): string | undefined {
    return super.get("markdown");
  }
  set markdown(value: string | undefined) {
    super.set("markdown", value);
  }
  get tags(): any[] {
    return super.get("tags");
  }
  set tags(value: any[]) {
    super.set("tags", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
  get text(): string | undefined {
    return super.get("text");
  }
  set text(value: string | undefined) {
    super.set("text", value);
  }
  get title(): string {
    return super.get("title");
  }
  set title(value: string) {
    super.set("title", value);
  }
}

Parse.Object.registerSubclass("APP_GlossaryEntry", GlossaryEntry);
