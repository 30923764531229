import Parse from "parse";

type OD3_Tenant = Parse.Object;

export interface AttachmentAttributes {
  id: string;
  objectId: string;
  createdAt: Date;
  updatedAt: Date;

  description?: string;
  file: Parse.File;
  image: boolean;
  name: string;
  tenant: OD3_Tenant;
}

export class Attachment extends Parse.Object<AttachmentAttributes> {
  static className: string = "APP_Attachment";

  constructor(data?: Partial<AttachmentAttributes>) {
    super("APP_Attachment", data as AttachmentAttributes);
  }

  get description(): string | undefined {
    return super.get("description");
  }
  set description(value: string | undefined) {
    super.set("description", value);
  }
  get file(): Parse.File {
    return super.get("file");
  }
  set file(value: Parse.File) {
    super.set("file", value);
  }
  get image(): boolean {
    return super.get("image");
  }
  set image(value: boolean) {
    super.set("image", value);
  }
  get name(): string {
    return super.get("name");
  }
  set name(value: string) {
    super.set("name", value);
  }
  get tenant(): OD3_Tenant {
    return super.get("tenant");
  }
  set tenant(value: OD3_Tenant) {
    super.set("tenant", value);
  }
}

Parse.Object.registerSubclass("APP_Attachment", Attachment);
