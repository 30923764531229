import * as React from "react";
import { Menu } from "antd";
import { useNavigate, useLocation } from "@opendash/router";
import { Icon } from "@opendash/icons";
import { getTitle, useAppState } from "../state";
import { useTranslation } from "@opendash/i18n";

const defaultMenuItems = [
  {
    key: "1",
    label: "app:menu.frontpage",
    path: "/",
  },
  {
    key: "2",
    label: "app:menu.invoice",
    path: "/rechnungserklaerer/rechnung",
  },
  {
    key: "7",
    label: "app:energy_saving_tips.menu",
    path: "/rechnungserklaerer/energiespartipps",
  },
  {
    key: "3",
    label: "app:menu.movies",
    path: "/rechnungserklaerer/filme",
  },
  {
    key: "5",
    label: "app:menu.glossar",
    path: "/rechnungserklaerer/glossar",
  },
  {
    key: "6",
    label: "app:menu.contact",
    path: "/rechnungserklaerer/contact",
  },
];

export const HeaderMenuComponent = React.memo(function HeaderMenuComponent({}) {
  const t = useTranslation();

  const { tenantConfig } = useAppState();

  const [items, setItemMenu] = React.useState(defaultMenuItems);
  const location = useLocation();

  const [selectedKey, setSelectedKey] = React.useState(
    items.find((_item) => location.pathname.startsWith(_item.path))?.key
  );

  const onClickMenu = (item: any) => {
    const clicked = items.find((_item) => _item.key === item.key);

    if (clicked?.path) {
      const path = clicked.path;

      if (path.startsWith("http://") || path.startsWith("https://")) {
        window.open(path, "_blank")?.focus();
      } else {
        navigate(path || "/");
      }
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/") {
      setSelectedKey("1");
    } else {
      var itemPaths = [...items];

      //if (itemPaths.length > 0 && itemPaths[0].key == "1") {
      itemPaths.shift();
      //}

      setSelectedKey(
        itemPaths.find((_item) => location.pathname.startsWith(_item.path))?.key
      );
    }
  }, [location.pathname]);

  React.useEffect(() => {
    let newitems = [...defaultMenuItems];

    if (tenantConfig?.get("documentLabels")) {
      for (const item of newitems) {
        if (item.key === "2") {
          item.label = "app:invoice.document_plural";
        }
      }
    }

    if (
      tenantConfig?.get("menuItemLabel") &&
      tenantConfig?.get("menuItemUrl")
    ) {
      newitems.push({
        key: "custom1",
        label: getTitle(tenantConfig?.get("menuItemLabel") as string),
        path: tenantConfig?.get("menuItemUrl") as string,
      });
    }

    if (
      tenantConfig?.get("menuItem2Label") &&
      tenantConfig?.get("menuItem2Url")
    ) {
      newitems.push({
        key: "custom2",
        label: getTitle(tenantConfig?.get("menuItem2Label") as string),
        path: tenantConfig?.get("menuItem2Url") as string,
      });
    }

    if (
      tenantConfig?.get("menuItem3Label") &&
      tenantConfig?.get("menuItem3Url")
    ) {
      newitems.push({
        key: "custom3",
        label: getTitle(tenantConfig?.get("menuItem3Label") as string),
        path: tenantConfig?.get("menuItem3Url") as string,
      });
    }

    if (tenantConfig?.get("hideVideos")) {
      newitems = newitems.filter((i) => i.key !== "3");
    }

    setItemMenu(newitems);
  }, [tenantConfig]);

  const navigate = useNavigate();

  return (
    <Menu
      className="customMenu"
      triggerSubMenuAction={"click"}
      selectedKeys={selectedKey ? [selectedKey] : []}
      mode="horizontal"
      onClick={onClickMenu}
      overflowedIndicator={
        <Icon style={{ fontSize: "20px", marginTop: "20px" }} icon="fa:bars" />
      }
    >
      {items.map((item) => (
        <Menu.Item key={item.key}>
          <h4>{t(item.label)}</h4>
        </Menu.Item>
      ))}
    </Menu>
  );
});
