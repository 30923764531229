import * as React from "react";
import { Descriptions } from "antd";
import { Icon } from "@opendash/icons";
import { useAppState } from "../state";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "@opendash/i18n";
import styled from "styled-components";

const ContactImageElement = styled.img`
  width: 100%;
`;

const ParaDiv = styled.div`
  p {
    line-height: 27.5px;

    margin-bottom: 0px !important;
  }
`;

export const ContactContent = React.memo(function ContactContent() {
  const { tenantConfig } = useAppState();
  const t = useTranslation();

  if (!tenantConfig) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      {tenantConfig.get("addressText") && (
        <Descriptions
          title=""
          layout="vertical"
          bordered
          style={{ padding: "10px" }}
        >
          <Descriptions.Item
            label={
              <div style={{ fontSize: "20px" }}>
                <b>
                  <Icon icon="fa:address-book" />
                  &nbsp;&nbsp;{t("app:contact.address")}
                </b>
              </div>
            }
            style={{ width: "100%" }}
          >
            <ParaDiv>
              <ReactMarkdown
                children={
                  tenantConfig.get("addressText")
                    ? tenantConfig.get("addressText")?.replace(":", ":&lrm;") +
                      "&lrm;"
                    : ""
                }
              />
            </ParaDiv>
          </Descriptions.Item>
        </Descriptions>
      )}
      {(tenantConfig.get("contactWeb") ||
        tenantConfig.get("contactPhone") ||
        tenantConfig.get("contactWhatsappLink") ||
        tenantConfig.get("contactEmail") ||
        tenantConfig.get("contactFax")) && (
        <Descriptions
          title=""
          layout="vertical"
          bordered
          style={{ padding: "10px" }}
        >
          <Descriptions.Item
            label={
              <div style={{ fontSize: "20px" }}>
                <b>
                  <Icon icon="fa:phone-alt" />
                  &nbsp;&nbsp;{t("app:contact.phone")}
                </b>
              </div>
            }
          >
            <div style={{ unicodeBidi: "plaintext" }}>
              <ReactMarkdown
                children={
                  tenantConfig.get("contactText")
                    ? tenantConfig.get("contactText") + "&lrm;"
                    : ""
                }
              />
            </div>

            {tenantConfig.get("contactWeb") && (
              <p>
                {t("app:contact.website")}:{" "}
                <a
                  dir="ltr"
                  href={tenantConfig.get("contactWeb")}
                  target="_blank"
                >
                  {tenantConfig.get("contactWeb")}
                </a>
              </p>
            )}

            {tenantConfig.get("contactPhone") && (
              <p>
                {t("app:contact.phoneentry")}:{" "}
                <a href={"tel:" + tenantConfig.get("contactPhone")}>
                  {tenantConfig.get("contactPhone")}
                </a>
              </p>
            )}

            {tenantConfig.get("contactWhatsappLabel") &&
              tenantConfig.get("contactWhatsappLink") && (
                <p>
                  {t("app:contact.whatsapp")}:{" "}
                  <a
                    href={tenantConfig.get("contactWhatsappLink")}
                    target="_blank"
                  >
                    {tenantConfig.get("contactWhatsappLabel")}
                  </a>
                </p>
              )}

            {tenantConfig.get("contactFax") && (
              <p>
                {t("app:contact.faxentry")}: {tenantConfig.get("contactFax")}
              </p>
            )}

            {tenantConfig.get("contactEmail") && (
              <p>
                {t("app:contact.mailentry")}:{" "}
                <a href={"mailto:" + tenantConfig.get("contactEmail")}>
                  {tenantConfig.get("contactEmail")}
                </a>
              </p>
            )}

            {tenantConfig.get("contactImage") && (
              <ContactImageElement
                src={tenantConfig.get("contactImage")?.url()}
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      )}
      {tenantConfig.get("openingHoursText") && (
        <Descriptions
          title=""
          layout="vertical"
          bordered
          style={{ padding: "10px" }}
        >
          <Descriptions.Item
            label={
              <div style={{ fontSize: "20px" }}>
                <b>
                  <Icon icon="fa:clock" />
                  &nbsp;&nbsp;{t("app:contact.opening")}
                </b>
              </div>
            }
          >
            <ParaDiv>
              <ReactMarkdown
                children={
                  tenantConfig.get("openingHoursText")
                    ? tenantConfig.get("openingHoursText") + "&lrm;"
                    : ""
                }
              />
            </ParaDiv>
          </Descriptions.Item>
        </Descriptions>
      )}
      {(!!tenantConfig.get("googleMaps") ||
        !!tenantConfig.get("routeDescriptionText")) && (
        <Descriptions
          title=""
          layout="vertical"
          bordered
          style={{ padding: "10px" }}
        >
          <Descriptions.Item
            label={
              <div style={{ fontSize: "20px" }}>
                <b>
                  <Icon icon="fa:route" />
                  &nbsp;&nbsp;{t("app:contact.route")}
                </b>
              </div>
            }
          >
            <ReactMarkdown
              children={
                tenantConfig.get("routeDescriptionText")
                  ? tenantConfig.get("routeDescriptionText") + "&lrm;"
                  : ""
              }
            />

            {/* 
          <pre>
            {JSON.stringify(
              {
                _: "TODO: Map richtig einbinden!",
                point: tenantConfig.get("mapGeoPoint"),
                label: tenantConfig.get("mapLabel"),
              },
              null,
              2
            )}
            </pre>*/}

            <div style={{ height: "400px", width: "100%" }}>
              <div style={{ margin: "0 -24px -16px -24px" }}>
                <iframe
                  height="400"
                  style={{ border: "0", width: "100%" }}
                  loading="lazy"
                  src={tenantConfig.get("googleMaps")}
                ></iframe>

                {/* 
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d644528.425551093!2d6.8925203741342775!3d50.87395406252683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47bf3ad30d7896cf%3A0x11c79d2632d441d!2sasew%20eupener%20stra%C3%9Fe%20gps!3m2!1d50.941019999999995!2d6.8909199999999995!5e0!3m2!1sde!2sde!4v1629450173025!5m2!1sde!2sde"
                height="400"
                style={{ border: "0", width: "100%" }}
                loading="lazy"
              />
*/}
              </div>
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
});
