import { Button, Modal } from "antd";
import * as React from "react";
import ReactMarkdown from "react-markdown";

import { useAppState } from "../state";
import { GlossaryEntry } from "../types";

interface Props {
  entry: GlossaryEntry;
}

const charBlocklist = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "[",
  "]",
];

// let count = 0;

export const GlossaryEntryDetails = React.memo<Props>(
  function GlossaryEntryDetails({ entry }) {
    const state = useAppState();
    const [details, setDetails] = React.useState<GlossaryEntry | null>(null);
    const md = React.useMemo(() => {
      if (!entry || !entry.get("text")) {
        return "";
      }

      let text = entry.get("text") as string;
      let textLowerCase = text.toLowerCase();

      const allKeywordsByLength = Object.keys(
        state.gloassryEntriesMapByTag
      ).sort((a, b) => {
        return b.length - a.length;
      });

      // for (const [keyword, e] of Object.entries(
      //   state.gloassryEntriesMapByTag
      // )) {
      for (const keyword of allKeywordsByLength) {
        const e = state.gloassryEntriesMapByTag[keyword];

        if (e.id === entry.id) {
          continue;
        }

        const keywordLowerCase = keyword.toLowerCase();

        let startIndex = 0;
        let index;

        while (
          (index = textLowerCase.indexOf(keywordLowerCase, startIndex)) > -1
        ) {
          const charBefore = text.charAt(index - 1);
          const charAfter = text.charAt(index + keyword.length);
          const isMatch =
            !charBlocklist.includes(charBefore) &&
            !charBlocklist.includes(charAfter);

          if (isMatch) {
            const match = text.slice(index, index + keyword.length);
            const replacement = `[${match}](/rechnungserklaerer/glossar/${e.id})`;

            text =
              text.slice(0, index) +
              replacement +
              text.slice(index + keyword.length);

            textLowerCase = text.toLowerCase();

            startIndex = index + replacement.length;
          } else {
            startIndex = index + keyword.length;
          }
        }
      }

      return text;
    }, [entry?.id]);
    if (!entry) {
      return null;
    }

    return (
      <div style={{ fontWeight: "normal" }}>
        <ReactMarkdown
          children={md}
          components={{
            a({ children, href }) {
              if (href?.startsWith("/rechnungserklaerer/glossar/")) {
                const id = href.replace("/rechnungserklaerer/glossar/", "");
                const detailEntry = state.glossaryEntriesMapById[id];

                return (
                  <a
                    children={children}
                    href={href}
                    onClick={(e) => {
                      e.preventDefault();
                      setDetails(detailEntry);
                    }}
                  />
                );
              }
              return <a href={href} children={children} />;
            },
          }}
        />

        <Modal
          title={details?.get("title")}
          visible={!!details}
          onCancel={() => {
            setDetails(null);
          }}
          footer={false}
        >
          {details && <GlossaryEntryDetails entry={details} />}
        </Modal>
      </div>
    );
  }
);
