import * as React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

import { useAppState } from "../state";

const ReactPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const VideoPlayer = React.memo<{ id: string }>(function VideoPlayer({
  id,
}) {
  const { videos } = useAppState();

  const video = videos.find((video) => video.id === id);

  if (!video) {
    return null;
  }

  const url = video.get("video")?.url();
  const thumbnail = video.get("image")?.url();

  if (!url || !thumbnail) {
    return null;
  }

  return (
    <ReactPlayerContainer>
      <ReactPlayer
        controls
        playing={true}
        volume={1}
        muted={true}
        width="100%"
        height="100%"
        url={url}
        light={thumbnail}
      />
    </ReactPlayerContainer>
  );
});
