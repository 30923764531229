import * as React from "react";
import styled from "styled-components";

import { useTranslation } from "@opendash/i18n";
import {
  Col,
  Collapse,
  Divider,
  Empty,
  Modal,
  Pagination,
  Row,
  Select,
} from "antd";
import { useNavigate, useParams } from "@opendash/router";
import { getInvoiceTitle, useAppState } from "../state";

import { useElementSizeThrottle } from "@opendash/core";
import Title from "antd/lib/typography/Title";
import { AppLayout } from "./AppLayout";
import { GlossaryEntryDetails } from "./GlossaryEntryDetails";
import { InvoiceIcon } from "./InvoiceIcon";
import { VideoPlayer } from "./VideoPlayer";

const { Option } = Select;

const INVOICE_WIDTH = 2480;
const INVOICE_HEIGHT = 3508;

const ContentContainer = styled.div`
  display: flex;
  scroll-behavior: smooth;
  .paddingClass {
    padding: 10px;
  }
  .paddingClassWithoutPadding {
    padding: 10px;
  }

  @media only screen and (max-width: 768px) {
    .paddingClass {
      padding: 0px;
      padding-top: 10px;
    }
    .paddingClassWithoutPadding {
      padding: 0px;
    }
  }

  .btntest {
    position: absolute;
    border-radius: 100%;
    border-top-left-radius: 10px;
    background-color: var(--primaryColor);
    color: var(--primaryTextColor);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    .btntest {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 786px) {
    .btntest {
      width: 29px;
      height: 29px;
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 512px) {
    .btntest {
      width: 25px;
      height: 25px;
      font-size: 10px;
    }
  }
`;

const PageContainer = styled.div`
  flex-grow: 0;
  width: 100%;
`;

const ExplainationContainer = styled.div`
  flex-grow: 1;
`;

const PageImageContainer = styled.div`
  position: relative;
  border: 1px solid #d9d9d9;
`;

const PageImageAnnotation = styled.span`
  position: absolute;
  /* left, top and background are calculated */
  height: 40px;

  width: auto;
  min-width: 200px;
  color: black;
  visibility: hidden;

  display: inline-flex;
  border-radius: 25px;

  .iconplacer {
    min-width:40px;
    font-size: 16px;
    font-weight:700,
    border-radius: 25px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    visibility: visible;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: visibility;
    z-index: 10;
    padding-left: 0px;
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.25s;
    transition-timing-function: ease;
    transition-delay: initial;
    transition-property: transform;
    border-radius: 50%;
    // border-top-left-radius: 10px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 40px;
    height: 40px;
  }

  .iconmarker {
    display: none;
  }

  .iconmarkerr {
    margin-left: -0px;
  }

  @media only screen and (min-width: 768px) {
    :hover {
      .iconmarkerl {
        z-index:9999999;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        padding-top: 10px;
        padding-right: 15px;
        padding-bottom: 10px;
        padding-left: 40px;
        margin-left: -30px;
        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
      .iconmarkerr {
        
        z-index:9999999;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        height: 40px;
        padding-top: 10px;
        padding-right: 50px;
        padding-bottom: 10px;
        padding-left: 15px;

        font-family: Roboto, Roboto, sans-serif;
        white-space: nowrap;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-delay: initial;
        animation-iteration-count: initial;
        animation-direction: initial;
        animation-fill-mode: initial;
        animation-play-state: initial;
        animation-name: initial;
        visibility: visible;
        transition-duration: 0.1s;
        transition-timing-function: ease;
        transition-delay: initial;
        transition-property: visibility;
      }
    }
  }

  

  @media only screen and (max-width: 1024px) {
    .iconplacer {
      min-width: 30px;
      width: 30px;
      height: 30px;
      font-size: 12px;
    }

    :hover {
      .iconmarkerl {
        height: 30px;
        font-size: 12px;
      }
      .iconmarkerr {
        height: 30px;
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .iconplacer {
      min-width: 25px;
      width: 25px;
      height: 25px;
      font-size: 10px;
    }

    :hover {
      .iconmarkerl {
        height: 25px;
        font-size: 10px;
      }
      .iconmarkerr {
        height: 25px;
        font-size: 10px;
      }
    }
  }
`;

interface Annotation {
  key: string;
  index: number;
  x: number;
  y: number;
  keyword: string;
  videoId?: string;
}

export const InvoiceRoute = React.memo(function InvoiceRoute() {
  // const [width, setWidth] = React.useState<number>(window.innerWidth);
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  // React.useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  const { width } = useElementSizeThrottle({ current: document.body }, 100);

  const savedLanguage = window.localStorage.getItem(
    "rechnungserklärer/language"
  );

  let isMobile: boolean = width <= 768;

  const t = useTranslation();
  const {
    invoices,
    invoicePages: invoicePagesAll,
    glossaryEntries,
    tenantConfig,
  } = useAppState();

  const documentLabels = tenantConfig?.get("documentLabels");

  const { invoiceId, pageId } = useParams();

  const [modalStatus, setModalStatus] = React.useState<boolean | false>(false);

  const navigate = useNavigate();

  const selectedInvoice = invoices.find((invoice) => invoice.id === invoiceId);

  const invoicePages = React.useMemo(
    () =>
      invoicePagesAll.filter((page) => page.get("invoice")?.id === invoiceId),
    [invoiceId]
  );

  const selectedPage = React.useMemo(
    () => invoicePages.find((page) => page.id === pageId),
    [invoicePages, pageId]
  );

  const imageHeight = selectedPage?.get("imageHeight") || INVOICE_HEIGHT;
  const imageWidth = selectedPage?.get("imageWidth") || INVOICE_WIDTH;
  const imageContainerRef = React.useRef<HTMLDivElement>(null);
  const imageContainerSize = useElementSizeThrottle(imageContainerRef, 1000);
  const imageContainerScale = imageContainerSize.width / imageWidth;

  const selectedPageAnnotations = React.useMemo(() => {
    if (!selectedPage) {
      return [];
    }

    const annotations: Annotation[] = selectedPage.get("annotations");

    return annotations;
  }, [selectedPage]);

  const selectedPageGlossaryEntries = React.useMemo(() => {
    if (!selectedPage) {
      return [];
    }

    const keywords: string[] = selectedPageAnnotations.map(
      (annotation) => annotation.keyword
    );

    return keywords.map((keyword) =>
      glossaryEntries.find((entry) => entry.get("keyword") === keyword)
    );
  }, [glossaryEntries, selectedPageAnnotations]);

  const [highlightedKeyword, setHighlightedKeyword] = React.useState<
    string | null
  >(null);

  const highlightedAnnotation = selectedPageAnnotations.find(
    (a) => a.keyword === highlightedKeyword
  );

  function setInvoice(key: string | null, replace = false) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${key}`, { replace });
    } else {
      navigate(`/rechnungserklaerer/rechnung`, { replace });
    }
  }

  function setInvoicePage(key: string | null, replace = false) {
    if (key) {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}/${key}`, {
        replace,
      });
    } else {
      navigate(`/rechnungserklaerer/rechnung/${invoiceId}`, { replace });
    }
  }

  function getGlossarView() {
    return (
      <Collapse
        activeKey={highlightedKeyword ? [highlightedKeyword] : []}
        onChange={(key) => {
          if (Array.isArray(key)) {
            setHighlightedKeyword(key[key.length - 1]);
          } else {
            setHighlightedKeyword(key);
          }
        }}
      >
        {selectedPageAnnotations.map((annotation, index, array) => {
          if (
            array.findIndex((a) => a.keyword === annotation.keyword) !== index
          ) {
            return null;
          }

          const entry = selectedPageGlossaryEntries[index];

          if (!entry) {
            return null;
          }

          return (
            <Collapse.Panel
              key={entry.get("keyword")}
              id={entry.get("keyword")}
              header={
                <span>
                  {annotation.index} - {entry.get("title")}
                </span>
              }
              children={
                <>
                  <GlossaryEntryDetails entry={entry} />
                  {annotation.videoId && (
                    <VideoPlayer id={annotation.videoId} />
                  )}
                </>
              }
            />
          );
        })}
      </Collapse>
    );
  }

  // select invoice if none is seleceted
  React.useEffect(() => {
    if (!invoiceId && invoices.length > 0) {
      setInvoice(invoices[0].id, true);
    }
  }, [invoices, invoiceId]);

  // handle invoice not found
  React.useEffect(() => {
    if (invoiceId && !selectedInvoice) {
      setInvoice(null, true);
      //message.error(t("app:invoices.error_invoice_not_found"));
    }
  }, [selectedInvoice, invoiceId]);

  // select page if none is seleceted
  React.useEffect(() => {
    if (!pageId && invoicePages.length > 0) {
      setInvoicePage(invoicePages[0].id, true);
    }
  }, [invoicePages, pageId]);

  // handle page not found
  React.useEffect(() => {
    if (pageId && !selectedPage) {
      setInvoicePage(null, true);
      //message.error(t("app:invoices.error_page_not_found"));
    }
  }, [selectedPage, pageId]);

  // handle page change for highlighted keywords
  React.useEffect(() => {
    setHighlightedKeyword(null);
  }, [pageId]);

  return (
    <AppLayout>
      {/* Page Header */}
      <div style={{ padding: "10px" }}>
        <Title level={3}>
          {documentLabels
            ? t("app:invoice.document_plural")
            : t("app:invoice.title")}
        </Title>
      </div>
      <ContentContainer style={{ padding: "10px" }}>
        <Row style={{ width: "100%" }}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 13, order: 1 }}
            lg={{ span: 15, order: 1 }}
            xl={{ span: 15, order: 1 }}
            style={{ width: "100%" }}
            className="paddingClassWithoutPadding"
          >
            <PageContainer>
              {/* Image View */}
              {selectedPage ? (
                <PageImageContainer ref={imageContainerRef} dir="ltr">
                  <img
                    loading="lazy"
                    src={selectedPage.get("image")?.url()}
                    style={{
                      width: imageContainerSize.width,
                      height: Math.round(imageHeight * imageContainerScale),
                    }}
                  />

                  {selectedPageAnnotations.map((annotation, index) => {
                    const active = annotation.keyword === highlightedKeyword;
                    const background = active
                      ? "var(--secondaryColor)"
                      : "var(--primaryColor)";
                    const color = active
                      ? "var(--secondaryTextColor)"
                      : "var(--primaryTextColor)";
                    const background_alpha = active
                      ? "var(--secondaryColor-alpha)"
                      : "var(--primaryColor-alpha)";
                    const left = Math.round(annotation.x * imageContainerScale);
                    const top = Math.round(annotation.y * imageContainerScale);

                    const entry = selectedPageGlossaryEntries[index];

                    if (isMobile) {
                      return (
                        <span
                          key={annotation.key}
                          className="btntest"
                          style={{
                            background,
                            color,
                            left,
                            top,
                          }}
                          onClick={() => {
                            highlightedKeyword == annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }}
                        >
                          {annotation.index}
                        </span>
                      );
                    } else {
                      return (
                        <PageImageAnnotation
                          key={annotation.key}
                          title={entry?.get("title")}
                          style={{ left, top }}
                          onClick={() => {
                            highlightedKeyword === annotation.keyword
                              ? setHighlightedKeyword(null)
                              : (setHighlightedKeyword(annotation.keyword),
                                document
                                  ?.getElementById(annotation.keyword)
                                  ?.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                  }),
                                isMobile
                                  ? setModalStatus(true)
                                  : setModalStatus(false));
                          }}
                        >
                          <span
                            className="iconplacer"
                            style={{ background, color }}
                          >
                            {annotation.index}
                          </span>
                          {isMobile ? (
                            <div />
                          ) : (
                            <span
                              className={
                                annotation.x > 1240
                                  ? "iconmarkerr"
                                  : "iconmarkerl"
                              }
                              style={{
                                background: background_alpha,
                                color,
                                transform:
                                  annotation.x > 1240
                                    ? "translateX(-100%)"
                                    : "",
                              }}
                            >
                              {entry?.get("title")}
                            </span>
                          )}
                        </PageImageAnnotation>
                      );
                    }
                  })}
                </PageImageContainer>
              ) : (
                <Empty
                  style={{
                    width: "100%",
                    height: "500px",
                    paddingTop: "200px",
                  }}
                  description={<span>{t("app:invoice.nopage")}</span>}
                />
              )}
            </PageContainer>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 11, order: 2 }}
            lg={{ span: 9, order: 2 }}
            xl={{ span: 9, order: 3 }}
            style={{ width: "100%" }}
            className="paddingClass"
          >
            <ExplainationContainer>
              {/* Invoice Navigation */}
              {invoices.length > 1 ? (
                <h4 style={{ width: "100%", textAlign: "left" }}>
                  {documentLabels
                    ? t("app:invoice.document_plural")
                    : t("app:invoice.invoice")}
                </h4>
              ) : (
                <div></div>
              )}
              {invoices.length > 1 ? (
                <Row>
                  {selectedInvoice?.get("id")}
                  {invoices.map((invoice) => (
                    <Col
                      key={invoice.id}
                      onClick={() => {
                        setInvoice(invoice.id);
                      }}
                      xs={8}
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        height: "110px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <Row
                        justify="center"
                        align="middle"
                        style={{
                          borderBottom:
                            selectedInvoice?.id === invoice.id
                              ? "3px solid var(--primaryColor)"
                              : "0px solid black",
                        }}
                      >
                        <Col span={24}>
                          <InvoiceIcon invoice={invoice} size="40px" />
                        </Col>
                        <Col
                          span={24}
                          style={{
                            lineHeight: "1.1",
                            height: "2.2em",
                            fontSize: "12px",
                          }}
                        >
                          {getInvoiceTitle(invoice)}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div></div>
              )}

              {/* Invoice Page Navigation */}

              {invoicePages.length > 1 ? (
                <div>
                  <h4 style={{ width: "100%", textAlign: "left" }}>
                    {documentLabels
                      ? t("app:invoice.page_plural")
                      : t("app:invoice.invoicepages")}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Pagination
                      hideOnSinglePage={true}
                      showSizeChanger={false}
                      responsive={true}
                      pageSize={1}
                      onChange={(x) => {
                        setInvoicePage(invoicePages[x - 1].id);
                      }}
                      defaultCurrent={1}
                      total={invoicePages.length}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                  <Divider />
                </div>
              ) : (
                <div></div>
              )}

              {/* 
              <Tabs
                size="large"
                activeKey={pageId}
                onChange={setInvoicePage}
                style={{
                  padding: "5px",
                }}
              >
                {invoicePages.map((page) => (
                  <Tabs.TabPane key={page.id} tab={page.get("title")} />
                ))}
              </Tabs>
*/}
              {/* Glossary View */}

              {selectedPageGlossaryEntries.length > 0 ? (
                <Col xs={0} sm={0} md={24} lg={24} xl={24}>
                  <h4 style={{ width: "100%", textAlign: "left" }}>
                    {t("app:invoice.glossar")}
                  </h4>
                  {getGlossarView()}
                </Col>
              ) : (
                <div></div>
              )}
            </ExplainationContainer>
          </Col>
          {selectedPageGlossaryEntries.length > 0 ? (
            <Col
              xs={{ span: 24, order: 3 }}
              sm={{ span: 24, order: 3 }}
              md={{ span: 0, order: 3 }}
              lg={{ span: 0, order: 3 }}
              xl={{ span: 0, order: 3 }}
              style={{ width: "100%" }}
              className="paddingClass"
            >
              <h4 style={{ width: "100%", textAlign: "left" }}>
                {t("app:invoice.glossar")}
              </h4>
              {getGlossarView()}
            </Col>
          ) : (
            <div></div>
          )}
        </Row>
      </ContentContainer>
      <Modal
        visible={modalStatus}
        title={glossaryEntries
          .find((entry) => entry.get("keyword") === highlightedKeyword)
          ?.get("title")}
        width={800}
        style={{ padding: "10px", maxHeight: "80vh", overflow: "scroll" }}
        destroyOnClose={true}
        footer={null}
        onCancel={() => {
          setModalStatus(false);
          setHighlightedKeyword(null);
        }}
      >
        <GlossaryEntryDetails
          entry={glossaryEntries.find(
            (entry) => entry.get("keyword") === highlightedKeyword
          )}
        />
        {highlightedAnnotation?.videoId && (
          <VideoPlayer id={highlightedAnnotation.videoId} />
        )}
      </Modal>
    </AppLayout>
  );
});
